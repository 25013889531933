import React, { useState, useRef } from "react";
import { useApiWithSessionExpiration } from "./useApiWithSessionExpiration";
import "./AddFiles.css";

const AddFiles = ({ accessToken, apiBaseUrl, handleSessionExpiration, taskId, onClose }) => {
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);
  const apiCall = useApiWithSessionExpiration(handleSessionExpiration);

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const validFiles = selectedFiles.filter((file) => {
      const fileType = file.type;
      return (
        fileType === "application/pdf" ||
        fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        fileType === "application/vnd.ms-excel.sheet.macroEnabled.12" ||
        fileType === "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      );
    });

    const totalSize = validFiles.reduce((acc, file) => acc + file.size, 0);
    const maxTotalSize = 800 * 1024 * 1024; // 800MB in bytes

    if (totalSize > maxTotalSize) {
      setError("Total file size exceeds 800MB limit.");
      return;
    }

    setFiles(validFiles);
    setError(null);
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      setError("Please select files to upload.");
      return;
    }

    setUploading(true);
    setError(null);

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });

    try {
      await apiCall(`${apiBaseUrl}files?taskId=${taskId}`, {
        method: "POST",
        accessToken: accessToken,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      onClose();
    } catch (err) {
      console.error("Error uploading files:", err);
      setError("Failed to upload files. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="add-files">
      <div>
        <h2>Add Files</h2>
        <div className="instruction-note-container">
          <div className="instruction">Select up to 800MB of PDF, Word, Excel, or PowerPoint files.</div>
          <div className="note">
            Note: Word and PowerPoint files will also be converted to PDF automatically. It may take up to 2 minutes for
            the conversion.
          </div>
        </div>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          multiple
          accept=".pdf,.docx,.xlsx,.xlsm,.pptx"
          style={{ display: "none" }}
        />
        <div className="select-files">
          <button className="select-files-button" onClick={() => fileInputRef.current.click()} disabled={uploading}>
            Select Files
          </button>
        </div>
        {files.length > 0 && (
          <ul className="file-list">
            {files.map((file, index) => (
              <li key={index}>
                {file.name} ({(file.size / (1024 * 1024)).toFixed(2)} MB)
              </li>
            ))}
          </ul>
        )}
        {error && <p className="error-message">{error}</p>}
      </div>
      <div>
        <div className="button-group">
          <button className="add-files-button" onClick={handleUpload} disabled={uploading || files.length === 0}>
            {uploading ? "Uploading..." : "Upload"}
          </button>
          <button className="cancel-add-files-button" onClick={onClose} disabled={uploading}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddFiles;
