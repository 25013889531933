import React, { useState, useEffect, useCallback } from "react";
import { useApiWithSessionExpiration } from "./useApiWithSessionExpiration";
import DatePicker from "react-datepicker";
import { addBusinessDays, setHours, setMinutes } from "date-fns";
import AddFiles from "./AddFiles";
import "./AddTask.css";

const AddTask = ({ accessToken, apiBaseUrl, handleSessionExpiration, onClose }) => {
  const [step, setStep] = useState(1);
  const [clients, setClients] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [newTaskId, setNewTaskId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  // Form state
  const [formData, setFormData] = useState({
    clientId: "",
    title: "",
    note: "",
    assignedToId: "",
    state: "OPEN",
    priority: "MEDIUM",
    dueDate: setMinutes(setHours(addBusinessDays(new Date(), 2), 19), 0),
    dueDateReason: "",
    type: "OTHER",
    isAdvertisement: true,
  });

  const apiCall = useApiWithSessionExpiration(handleSessionExpiration);

  // Fetch clients and employees on component mount
  const fetchData = useCallback(async () => {
    try {
      const [clientsResponse, employeesResponse] = await Promise.all([
        apiCall(`${apiBaseUrl}clients`, {
          method: "GET",
          accessToken: accessToken,
        }),
        apiCall(`${apiBaseUrl}employees?isActive=true`, {
          method: "GET",
          accessToken: accessToken,
        }),
      ]);

      setClients(clientsResponse.data.sort((a, b) => a.name.localeCompare(b.name)));
      setEmployees(employeesResponse.data);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Failed to load required data. Please try again.");
    }
  }, [apiCall, apiBaseUrl, accessToken]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "assignedToId") {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
        state: value ? "ASSIGNED" : "OPEN",
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleDueDateChange = (date) => {
    setFormData((prev) => ({
      ...prev,
      dueDate: date,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      const response = await apiCall(`${apiBaseUrl}tasks`, {
        method: "POST",
        accessToken: accessToken,
        data: {
          ...formData,
          dueDate: formData.dueDate.toISOString(),
        },
      });

      setNewTaskId(response.data.id);
      setStep(2);
    } catch (err) {
      console.error("Error creating task:", err);
      setError("Failed to create task. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAddFilesClose = () => {
    onClose();
  };

  if (step === 2) {
    return (
      <AddFiles
        accessToken={accessToken}
        apiBaseUrl={apiBaseUrl}
        handleSessionExpiration={handleSessionExpiration}
        taskId={newTaskId}
        onClose={handleAddFilesClose}
      />
    );
  }

  return (
    <div className="add-task">
      <h2>Create New Task</h2>
      {error && <div className="error-message">{error}</div>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="clientId">Client *</label>
          <select id="clientId" name="clientId" value={formData.clientId} onChange={handleInputChange} required>
            <option value="">Select a client</option>
            {clients.map((client) => (
              <option key={client.id} value={client.id}>
                {client.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group title-note-reason">
          <label htmlFor="title">Title *</label>
          <input type="text" id="title" name="title" value={formData.title} onChange={handleInputChange} required />
        </div>

        <div className="form-group title-note-reason">
          <label htmlFor="note">Note</label>
          <textarea id="note" name="note" value={formData.note} onChange={handleInputChange} rows={4} />
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="assignedToId">Assigned To</label>
            <select id="assignedToId" name="assignedToId" value={formData.assignedToId} onChange={handleInputChange}>
              <option value="">Unassigned</option>
              {employees.map((emp) => (
                <option key={emp.id} value={emp.id}>
                  {emp.person.displayName}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="state">State</label>
            <select id="state" name="state" value={formData.state} onChange={handleInputChange}>
              <option value="OPEN">OPEN</option>
              <option value="ASSIGNED">ASSIGNED</option>
              <option value="IN_PROGRESS">IN PROGRESS</option>
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="priority">Priority</label>
            <select id="priority" name="priority" value={formData.priority} onChange={handleInputChange}>
              <option value="URGENT">URGENT</option>
              <option value="HIGH">HIGH</option>
              <option value="MEDIUM">MEDIUM</option>
              <option value="LOW">LOW</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="type">Material Type</label>
            <select id="type" name="type" value={formData.type} onChange={handleInputChange}>
              <option value="OTHER">OTHER</option>
              <option value="PITCHBOOK">PITCHBOOK</option>
              <option value="DDQ">DDQ</option>
              <option value="FACT_SHEET">FACT SHEET</option>
              <option value="WEBSITE">WEBSITE</option>
              <option value="INVESTOR_LETTER">INVESTOR LETTER</option>
              <option value="PPM">PPM</option>
              <option value="SOCIAL_MEDIA">SOCIAL MEDIA</option>
              <option value="EMAIL">EMAIL</option>
              <option value="PERFORMANCE_DATA">PERFORMANCE DATA</option>
              <option value="WEBINAR">WEBINAR</option>
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="dueDate">Due Date</label>
            <DatePicker
              id="dueDate"
              selected={formData.dueDate}
              onChange={handleDueDateChange}
              showTimeSelect
              dateFormat="Pp"
              className="date-picker"
            />
          </div>

          <div className="form-group">
            <label htmlFor="isAdvertisement">Review As Advertisement</label>
            <select
              id="isAdvertisement"
              name="isAdvertisement"
              value={formData.isAdvertisement}
              onChange={(e) =>
                handleInputChange({
                  target: {
                    name: "isAdvertisement",
                    value: e.target.value === "true",
                  },
                })
              }
            >
              <option value={true}>YES</option>
              <option value={false}>NO</option>
            </select>
          </div>
        </div>

        <div className="form-group title-note-reason">
          <label htmlFor="dueDateReason">Due Date Reason</label>
          <input
            type="text"
            id="dueDateReason"
            name="dueDateReason"
            value={formData.dueDateReason}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-actions">
          <button type="button" onClick={onClose}>
            Cancel
          </button>
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Creating..." : "Create Task"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddTask;
